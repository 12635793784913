import React, { useEffect } from "react";
import { navigate } from "@reach/router";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { i18n } = useTranslation();

  // eslint-disable-next-line no-console
  console.clear();
  // eslint-disable-next-line no-console
  console.log(i18n.language);

  useEffect(() => {
    switch (i18n.language) {
      case "es-ES":
        navigate("/es/");
        break;
      case "pt-PT":
        navigate("/pt/");
        break;
      default:
        navigate("/pt/");
        break;
    }
  }, []);

  return <></>;
};

export default Home;
